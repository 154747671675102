.content-block.specialPromo {
  overflow: hidden;
  .black-bar {
    position: absolute;
    top:0;
    left:0;
    height: 100%;
    width:50%;
    background-color: #000;
    display: block;
  }
  .specialPromo2 {
    background-color: #fff;
    display: block;
    padding-left:$master-padding-2x;
    @media only screen and (max-width: $breakpoint-small) {
      padding-left:$master-padding-mobile-2x;
    }
    .row1 {
      width: 100%;
      margin-bottom: 20px;
    }
    .row2 {
      width: 100%;
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      @media only screen and (max-width: $breakpoint-small) {
        display: block;
      }
    }
    .photo {
      width:40vw;
      .img {
        width:100%;
        aspect-ratio: 1;
      }
      @media only screen and (max-width: $breakpoint-small) {
        width:calc(100% - $master-padding-mobile);
      }
    }
    .right {
      position: absolute;
      right:0;
      top:0;
      width: calc(100% - 45vw);
      height: 100%;
      margin-left: 5vw;
      flex-grow: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      @media only screen and (max-width: $breakpoint-small) {
        position: relative;
        margin-top: 20px;
        margin-left: 0;
        width: 100%;
      }

      .rte {
        width: 80%;
        @media only screen and (max-width: $breakpoint-small) {
          width: calc(100% - $master-padding-mobile);
        }
        * {
          font-size: 32px;
          line-height: 1.2em;
        }
      }
      .film-strip-outer {
        @media only screen and (max-width: $breakpoint-small) {
          margin-top: 20px;
        }
      }
      .film-strip {
        .quick-thumbs {
          width: calc(100% - $master-padding);
          @media only screen and (max-width: $breakpoint-small) {
            width: calc(100% - $mobile-thumbs-peek);
          }

          //height: 100%;
          .swiper-slide {
            aspect-ratio: 1;
            @include punch-in-hover();
          }
        }
      }

    }
  }
}