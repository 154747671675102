/////////////////////////////////////////////////////////
//GLOBAL TAGS
/////////////////////////////////////////////////////////
html, body {
  background-color: $color-light;
  scroll-behavior: smooth;
}

body {
  @include vh100();
  &.no-scroll {
    overflow: hidden;
    //height: 100%;
  }
}
.scroll-check-trigger {
  overflow: visible;
}

#container {
  width: 100%;
}

#content {
  //@include vh100();
}
.content-block {

}

#meta-sitewidth {
  display: none;
  z-index: 0;
  padding-top: $max-width-site;
}
#meta-breakpoint {
  display: none;
  z-index: 0;
  padding-top: $breakpoint-small;
}

a {
  text-decoration: none;
}
.site-content {
  //overflow-x: hidden;
  //overflow-y: visible;
}

.generic-holder {
  @include master-padding-tb();
  min-height: 300px;

  .generic-holder-content {
    padding-top: 40px;
  }
}



.generic-lr {
  display: flex;
  justify-content: space-between;
  .l {
    width: 100%;
    margin-right: 100px;
  }
  .r {
    @include widthMaxMin(375px);
  }
  @media only screen and (max-width: $breakpoint-small) {
    flex-wrap: wrap;
    .l {
      width: 100%;
      margin-right: 0
    }
    .r {
      @include widthMaxMin(100%);
      margin-top: 40px;
    }
  }
}


.strip-hider-trigger {
  .quick-thumbs-side {
    visibility: hidden;
    opacity: 0;
    @include transition-all();
    display: none;
    &.more-slides {
      display: flex;
    }
    &.initial-hide {
      opacity: 0;
      @include transition-all();
      visibility: hidden;
    }
  }
  &:hover {
    .quick-thumbs-side {
      visibility: visible;
      opacity: 1;
      &.initial-hide {
        opacity: 0;
        @include transition-all();
        visibility: hidden;
      }
    }
  }
}

.overlay-hook-yt-hider {
  visibility: hidden;
}




#videoOverlay {
  display: none;
}
body.page-home {
  #videoOverlay {
    display: block;
  }
}
#videoOverlay {
  position: fixed;
  z-index: 99999999;
  background-color: #000;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  .videoOverlay-inner {
    padding:0;
    width: 100%;
    height: 100%;
    video {
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
}