@import url("https://use.typekit.net/qhk6ofz.css");

@mixin font-global-regular() {
  font-family: "adrianna", sans-serif;
  font-weight: 400;
}
@mixin font-global-bold() {
  font-family: "adrianna", sans-serif;
  font-weight: 700;
}

@mixin font-adrianna-light() {
  font-family: "adrianna", sans-serif;
  font-weight: 300;
}
@mixin font-adrianna-regular() {
  font-family: "adrianna", sans-serif;
  font-weight: 300;
}
@mixin font-adrianna-regular() {
  font-family: "adrianna", sans-serif;
  font-weight: 400;
}
@mixin font-adrianna-demibold() {
  font-family: "adrianna", sans-serif;
  font-weight: 600;
}
@mixin font-adrianna-bold() {
  font-family: "adrianna", sans-serif;
  font-weight: 700;
}
@mixin font-adrianna-condensed-light() {
  font-family: "adrianna-condensed", sans-serif;
  font-weight: 300;
}

@mixin font-adrianna-condensed-regular() {
  font-family: "adrianna-condensed", sans-serif;
  font-weight: 400;
}
@mixin font-adrianna-condensed-demibold() {
  font-family: "adrianna-condensed", sans-serif;
  font-weight: 600;
}

@mixin font-roboto-regular() {
  font-family: "roboto", sans-serif;
  font-weight: 400;
}
@mixin font-roboto-medium() {
  font-family: "roboto", sans-serif;
  font-weight: 500;
}