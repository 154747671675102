header.header {
  top:0;
  left:0;
  width:100%;
  position: fixed;
  @include transition-all();
  z-index: 10000;
  //background-color: rgba(255,255,255,1);
  background-color: rgba(255,255,255,0);
  //display: none;
  &.first-load-done {
    //display: block;
  }
  .header2 {
    padding:50px 0;
    @media only screen and (max-width: $breakpoint-small) {
      padding:20px 0;
    }
  }

  .header-logo {
    .lr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .l {
        width: 50%;
        top:0;
        img, svg {
          width: 194px;
          height: auto;
        }
        .logo {
          svg {
            polygon, path {
              fill: #000;
              @include transition-all();
            }
          }
        }
      }
      .r {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .search {
          margin-right: 20px;
          cursor: pointer;
          @include transition-all();
          transform: scaleX(-1);
        }
        .profile {
          margin-right: 20px;
          cursor: pointer;
          @include transition-all();
          svg {
            width:26px;
            height: auto;
          }
        }
        .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
          @include transition-all();
        }
      }

    }
  } //header-logo
}
#header-pusher {
  width: 100%;
  display: block;
  height: 155px;
}