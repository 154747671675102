#header-alert {
  height: $alertHeight;
  background-color: #000;
  z-index: 999998;
  padding:0 0;
  width: 100%;
  position: relative;
  top:0;
  left: 0;
  .swiper-wrapper {
    height: $alertHeight;
    .swiper-slide, .swiper-slide-inner {
      height: $alertHeight;
      text-align: center;
      p {
        text-align: center;
        line-height: 1em;
      }
    }
  }
  .rte {
    text-align: center;
    * {
      color: #fff;
    }
  }
  .swiper-slide-inner {
    width: calc(100% - 100px);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .swiper-button-next, .swiper-button-prev {
    width: 30px;
    height: 30px;
    top:30px;
    svg {
      width: 10px;
    }
  }
  .swiper-button-next {
    right:0;
  }
  .swiper-button-prev {
    left:0;
  }
}

