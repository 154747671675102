.content-block.specialNote {
  overflow: hidden;
  .black-bar {
    position: absolute;
    top:0;
    left:0;
    height: 100%;
    width:50%;
    background-color: #000;
    display: block;
  }
  .specialNote2 {
    background-color: #fff;
    display: block;
    padding-left: 0;
    width: 100%;
    @media only screen and (max-width: $breakpoint-small) {
      padding-left: 5vw;
      width: calc(100% - 5vw);
    }
    .row1 {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @media only screen and (max-width: $breakpoint-small) {
        display: block;
      }
    }
    .photo {
      @include widthMaxMin($filmColumn);
      //@include widthMaxMin(185px);
      @media only screen and (max-width: $breakpoint-small) {
        @include widthMaxMin(50%);
      }
      display: flex;
      justify-content: flex-end;
      @media only screen and (max-width: $breakpoint-small) {
        justify-content: flex-start;
      }
      .photo2 {
        @include widthMaxMin(200px);
      }
      img {
        width:100%;
        height: auto;
      }
    }
    .right {
      width: calc(100% - 185px);
      margin-left: 5vw;
      @media only screen and (max-width: $breakpoint-small) {
        margin-left: 0;
        width: 100%;
        margin-top:20px;
      }
      .rte {
        width: 80%;
        * {
          font-size: 32px;
          line-height: 1.2em;
        }
        h2 {
          margin-bottom: 0px;
        }
        .desc {
          p {
            @include font-adrianna-light();
            font-size: 18px;
            //text-transform: uppercase;
          }
        }
        .note {
          margin-top: 20px;
          * {
            font-size: 16px;
            line-height: 1.8em;
            @media only screen and (max-width: $breakpoint-small) {
              line-height: 1.6em;
            }
          }
        }
      }
    }
  }
}