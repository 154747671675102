body.page-film.above-scroll-check  {
  header.header {
    .r {
      .icons {
        svg {
          path {
            fill: #fff !important;
          }
        }
      }
    }
  }
}
body.page-home {
  #header-pusher {
   display: none;
  }
  header.header {
    .l {
      .logo {
        svg {
          polygon, path {
            fill: #fff!important;
          }
        }
      }
    }
    .r {
      .icons {
        svg {
          path {
            fill: #fff!important;
          }
        }
      }
      .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
        background-color: #fff!important;
      }
    }
  }

  &.past-scroll-check {
    header.header {
      .l {
        .logo {
          svg {
            polygon, path {
              fill: #000!important;
            }
          }
        }
      }
      .r {
        .icons {
          svg {
            path {
              fill: #000!important;
            }
          }
        }
        .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
          background-color: #000!important;
        }
      }
    }
  }

  &.above-scroll-check {
    header.header {
      background-color: rgba(255,255,255,0);
    }
  }
}

body.mode-nav {
  header.header {
    .r {
      .icons {
        visibility: hidden;
        opacity: 0;
      }
    }
  }
}



body.mode-buy,
body.mode-film {
  header.header {
    .r {
      .icons {
        visibility: hidden;
        opacity: 0;
      }
      .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
        background-color: #fff!important;
      }
    }
  }
  #mobile-nav-overlay-outer .email-member-strip .email-flex .field-submit span {
    @extend .link-underline-leftright-white;
  }
}



////////////////
// SEARCH
#search-bar, .container-all {
  @include transition-all()
}
body.page-search,
body.search-bar {
  $dist: 200px;
  .header {
    //margin-top: $dist;
  }

  #search-bar {
    height: $dist;
    visibility: visible;
  }
}
body.search-bar {
  .header {
    .r {
      opacity: 0;
      visibility: hidden;
    }
  }
  .container-all {
    //top:$dist;
  }
}
body.page-search {
  #search-bar {
    .close-holder {
      display: none;
    }
  }
  .header {
    .r {
      .search {
        display: none;
      }
    }
  }
}

.hide-element {
  @include transition-all();
}
#header-scrim {
  opacity:0;
  visibility: hidden;
  background-color: rgba(255,255,255,0);
  @include transition-all();
  width: 100%;
  position: fixed;
  top:0;
  left:0;
  z-index: 10;
  height: 154px;
  pointer-events: none;
}

body.scroll-dir-up {
  .hide-element {
    opacity:1;
    visibility: visible;
  }
  #header-scrim {
    opacity:1;
    visibility: visible;
    background-color: rgba(255,255,255,1);
  }

}


body.mode-profile,
body.mode-film,
body.mode-nav,
body.mode-buy {
  &.scroll-dir-down,
  &.scroll-dir-up {
    .header {
      opacity: 1!important;
      visibility: visible!important;
    }
    #mobile-nav-hamburger {
      opacity: 1!important;
      visibility: visible!important;
    }
  }
  @media only screen and (max-width: $breakpoint-small) {
    .header {
      .logo {
        visibility: hidden;
        opacity: 0;
      }
    }
  }
}



body.page-home header.header .r .is-active .hamburger-inner,
body.page-film header.header .r .is-active .hamburger-inner {
  background-color: rgba(0,0,0,0) !important;

}

body.scroll-dir-down {
  .hide-element {
    opacity:0;
    visibility: hidden;
  }
  #header-scrim {
    opacity:0;
    visibility: hidden;
    background-color: rgba(255,255,255,0);
  }

}
body.above-scroll-check {
  #header-scrim {
    opacity:0!important;
    visibility: hidden!important;
    background-color: rgba(255,255,255,0)!important;
  }
  header.header .r .is-active .hamburger-inner:before,
  header.header .r .is-active .hamburger-inner:after {
    background-color: rgba(0,0,0,1) !important;
  }
}

// CRAZY ANNOYING - MOBILE FILM PAGE HAS ITS OWN SCROLL CHECK
body.page-film {
  @media only screen and (max-width: $breakpoint-small) {
    #header-pusher {
      display: none;
    }

    &.mode-buy,
    &.mode-film,
    &.above-scroll-check {
      #header-scrim {
        opacity: 0 !important;
        visibility: hidden !important;
        background-color: rgba(255, 255, 255, 0) !important;
      }

      header.header .header-logo .lr .l .logo svg polygon,
      header.header .header-logo .lr .l .logo svg path {
        fill: #fff!important;
      }
      .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
        background-color: #fff!important;
      }
    }
    &.mode-buy,
    &.mode-film {
      header.header .r .is-active {
        .hamburger-inner {
          background-color: rgba(255,255,255,0)!important;
        }
        .hamburger-inner::after,
        .hamburger-inner::before {
          background-color: #fff!important;
        }
      }
    }
  }

}



body.alert-enabled {
  header.header {
    position: sticky;
    top: 0;
    .header2 {
      position: absolute;
      top:0;
      width: 100%;
    }
  }
  #search-bar {
    top: 17px;
  }
  #featured-panel {
    //top: $alertHeight;
    top:0;
    min-height: calc(100vh - $alertHeight);
  }
}

body.search-bar.page-home {
  header.header {
    .l {
      .logo {
        svg {
          polygon, path {
            fill: #000!important;
          }
        }
      }
    }
    .r {
      .icons {
        svg {
          path {
            fill: #000!important;
          }
        }
      }
      .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
        background-color: #000!important;
      }
    }
  }
}

