// SIZES
$headerTall:175px;
$headerSmall:120px;
$filmColumn:330px;

$alertHeight:47px;


//COLORS
$color-light: #ffffff;
$color-gray: #797979;
$color-gray-dark: #3A3A3A;
$color-dark: #191919;

$color-hover: #191919; //#257DF9;
$color-red: #9A0633;
$color-error: $color-red;
