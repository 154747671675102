body.page-film {
  .mobile-top {
    display: none;
    @media only screen and (max-width: $breakpoint-small) {
      display: block;
    }
    width: 100%;
    aspect-ratio: 1;
    height: auto;
    .mobile-top-panel {
      width: 100%;
      height: 100%;
    }
    margin-bottom: 40px;
    .film-titles {
      position: absolute;
      top:0;
      left:0;
      height: 100%;
      width: 100%;
      .capper, .capper-padded {
        height: 100%;
      }
      .film-titles2 {
        min-height: unset;
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        .rt {
          width: 35px;
          margin-left: 10px;
          top:5px;
        }

        .nyt {
          width: 35px;
          margin-left: 10px;
          top:5px;
        }
      }
      ul {
        bottom: 20px;
      }
    }
    .video-play {
      width: 40px;
      height: 40px;
      cursor: pointer;
      position: absolute;
      top: calc(50% - 20px);
      left: calc(50% - 20px);
      .icon {
        width: 100%;
        height: auto;
        svg {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  .showtimes-outer {
    margin-top: 20px;

    .load-more {
      text-align: center;
      margin-top: 50px;
    }

    #showtimes-search-location,
    #showtimes-search-button,
    #showtimes-search-button2 {
      @include transition-all();
      &.disabled {
        opacity:0.25;
      }
    }
    #showtimes-search-button2 {
      cursor: pointer;
      align-items: center;
      svg {
        transform: scaleX(-1);
      }
    }

    .showtimes-search {
      display: flex;
      margin-bottom: 20px;
      input {
        width: calc(100% - 100px);
        margin-right: 20px;
        border:1px solid #000;
        padding:10px 10px;
        @include font-adrianna-demibold();
        font-size: 18px;
        color:#000;
        @media only screen and (max-width: $breakpoint-small) {
          width: calc(100% - 25px);
          font-size: 16px;
        }
      }
      .btn {
        @include widthMaxMin(100px);
      }
    }

    #showtimes-search-current {
      @include font-adrianna-demibold();
      font-size: 16px;
      color:#000;
      margin:10px 0;
    }

    #showtimes-outer {
      width: 100%;
    }

    .date-boxes {
      width: 100%;
      height: 100px;
      user-select: none;
      .date-boxes1 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: scroll;
        height: 100%;
      }
      .date-boxes2 {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
      }
      .box {
        display: inline-block;
        border: 1px solid #000;
        @include widthMaxMin(80px);
        @include heightMaxMin(80px);
        margin-right: 20px;
        cursor: pointer;
        @include transition-all();
        user-select: none;

        &:last-child {
          margin-right: 0;
        }

        .box2 {
          //padding: 10px 5px;
          //width: calc(100% - 10px);
          //height: calc(100% - 10px);
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;

          .month, .weekday {
            @include font-adrianna-light();
            font-size: 15px;
            text-transform: uppercase;
            margin: 0;
            text-align: center;
            line-height: 1em;
            color: #000;
            @include transition-all();
          }

          .day {
            @include font-adrianna-bold();
            font-size: 18px;
            text-transform: uppercase;
            margin: 5px 0;
            text-align: center;
            line-height: 1em;
            color: #000;
            @include transition-all();
          }
        }

        //box2
        &.active, &:hover {
          background-color: #000;

          .box2 {
            .month, .weekday, .day {
              color: #fff;
            }
          }
        }
      } //box
    } //date-boxes

    .date-boxes-outer-desktop {
      width: 100%;
      display: block;
      @media only screen and (max-width: $breakpoint-small) {
        display: none;
      }
      .date-boxes {
        height: 82px;
        .date-boxes1 {
          overflow: hidden;
        }
      }
      .swiper {
        width: 100%;
        .swiper-slide {
          width: auto;
        }
      }
      .quick-thumbs-side {
        width:36px;
        min-width: 36px;
        svg {
          width:15px;
          height: auto;
        }
      }
    }
    .date-boxes-outer-mobile {
      display: none;
      @media only screen and (max-width: $breakpoint-small) {
        display: block;
        width: 100%;
      }
    }

    .theater-rows {
      margin-top: 10px;

      .theater-rows-date {
        visibility: hidden;
        opacity: 0;
        @include transition-all();
        height: 0;
        overflow: hidden;
        &.active {
          visibility: visible;
          opacity: 1;
          height: auto;
        }
        .theater-row {
          border-bottom: 1px solid #fff;
          margin: 10px 0;
          padding: 10px 0 20px 0;
          &.more {
            display: none;
          }

          .theater-row-lr {
            display: block;
            //align-items: flex-end;
            //justify-content: flex-start;
            @media only screen and (max-width: $breakpoint-small) {
              display: block;
            }

            .tr-l {
              width: 100%;
              display: block;
              //min-width: 30%;
              //width: auto;
              //max-width: 50%;
              //margin-right: 20px;
              @media only screen and (max-width: $breakpoint-small) {
                width: 100%;
                margin-right: 0;
              }
              h1, h2, h3, h4,h5, h6 {
                margin-bottom: 10px;
              }
            }
            .desc {
              display: flex;
              align-items: flex-end;
              justify-content: flex-start;
              @media only screen and (max-width: $breakpoint-small) {
                display: block;
              }
            }
            .times0 {
              display: flex;
              align-items: flex-end;
              justify-content: flex-start;
            }
            .times {
              margin-left: 20px;
              @media only screen and (max-width: $breakpoint-small) {
                margin-left: 0;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
              }
              .time {
                top:-5px;
                background-color: #000;
                padding: 4px 10px 5px;
                color: #fff;
                @include font-roboto-medium();
                font-size: 15px;
                cursor: pointer;
                line-height: 1em;
                border: 1px solid #000;
                display: inline-block;
                @include transition-all();
                margin-left: 20px;
                margin-top: 10px;
                min-width: 66px;
                text-align: center;

                &:hover {
                  background-color: #fff;
                  color: #000;
                }

                @media only screen and (max-width: $breakpoint-small) {
                  margin-left: 0;
                  margin-right: 20px;
                }
              }
            }

            .tr-r {
              min-width: 100px;
              width: auto;
              //width:50%;
              @media only screen and (max-width: $breakpoint-small) {
                width: 100%;
              }

            }
          }
        }
      }
    }
  }

}