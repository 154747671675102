$dark: #191919!important; //$color-dark
$light: #fff!important;
body.mode-dark {
  background-color: $dark;
  .rte,
  footer.footer .legal-lr .mininav ul li .spacer,
  footer.footer .legal-lr .rowline p,
  .trailer-bugs .ellipsis-outer-label,
  #showtimes-search-current *,
  .trailer-bugs .bugs .txt,
  .trailer-bugs .official-trailer .txt,
  .trailer-bugs .label .txt,
  #search-bar .inputs input,
  .all-library .lr .r span.txt-clicker.selected,
  .all-library .lr .r span.txt-clicker.notselected,
  &.page-general .rte a,
  &.page-contest .rte a {
    color: $light;
  }
  footer.footer {
    border-top: $light;
  }
  .hamburger-inner,
  .hamburger-inner::after,
  .hamburger-inner::before,
  .ellipsis-outer.with-label .ellipsis-pad .ellipsis .dot,
  .ellipsis-outer.dark .ellipsis-pad .ellipsis .dot{
    background-color: $light;
  }
  header.header .header-logo .lr .l .logo svg polygon,
  header.header .header-logo .lr .l .logo svg path,
  header.header .header-logo .lr .r .profile svg path,
  header.header .header-logo .lr .r .search svg path,
  footer.footer .legal-lr .main-logo svg,
  .social-icons .icon svg path,
  .trailer-bugs .bugs .icon svg  path,
  .trailer-bugs .official-trailer .icon svg  path,
  .trailer-bugs .label .icon svg path,
  #search-bar .inputs #search-submit svg  path{
    fill: $light;
  }
  footer.footer .legal-lr .nav ul li a,
  footer.footer .legal-lr .nav ul li .alink,
  footer.footer .legal-lr .mininav ul li a,
  footer.footer .legal-lr .mininav ul li #ot-sdk-btn,
  .trailer-bugs .official-trailer .txt {
    color: $light;
    &:before, &:after {
      background-color: $light;
    }
  }

  #search-bar .inputs {
    border-bottom-color: $light;
    input {
      background-color: $dark;
    }
  }

  .btn {
    background-color: $dark;
    border-color: $light;
    color: $light;
    &:hover {
      border-color: $light;
      background-color: $light;
      color: $dark;
    }
  }

  //sidebar
  #mobile-nav-overlay-outer { //.mode-film

    background-color: $light;

    .rte *, h1, h2, h3, h4, h5, h6, #ellipsis-link {
      color: $dark;
    }

    .btn {
      background-color: $light;
      border-color: $dark;
      color: $dark;
      &:hover {
        background-color: $dark;
        color: $light;
      }
    }

    .social-icons .icon svg path {
      fill: $dark;
    }

    .bugs-outer .bug .bug-icon.regular img {
      filter: invert(1);
      &.Hulu {
        filter: none;
      }
    }
  }
  #mobile-nav-hamburger.mode-film .is-active .hamburger-inner::after,
  #mobile-nav-hamburger.mode-film .is-active .hamburger-inner::before {
    background-color: $dark;
  }


  //header
  &.scroll-dir-up #header-scrim {
    background-color: $dark;
  }
  &.scroll-dir-down #header-scrim {
    background-color: rgba(0,0,0,0);
  }

  .black-sidebar {
    .black-sidebar-bg {
      background-color: $light;
    }
    .rte {
      background-color: $dark;
    }
    h1 {
      color: $light;
    }
  }

  #become-member-popup {
    .rte * {
      color: $dark;
    }
  }

  &.search-bar {
    .global-close.dark:before,
    .global-close.dark:after {
      background-color: $light;
    }
    header.header .header-logo .lr .l img,
    header.header .header-logo .lr .l svg {
      path {
        fill: $light;
      }
      polygon {
        stroke: $light;
        fill: $light;
      }
    }
  }

  //
  &.page-home.past-scroll-check header.header .r .hamburger-inner,
  &.page-home.past-scroll-check header.header .r .hamburger-inner::after,
  &.page-home.past-scroll-check header.header .r .hamburger-inner::before {
    background-color: $light;
  }

  //&.mode-dark.mode-nav.page-home.past-scroll-check header.header .r .hamburger-inner,
  //&.mode-dark.mode-nav.page-home.past-scroll-check header.header .r .hamburger-inner::after,
  //&.mode-dark.mode-nav.page-home.past-scroll-check header.header .r .hamburger-inner::before {
  //  background-color: $dark;
  //}
  //&.mode-dark.mode-dark.mode-nav.page-home.past-scroll-check header.header .r .hamburger-inner {
  //  background-color: rgba(0,0,0,0) !important;
  //}
  &.mode-dark.mode-nav header.header .r .hamburger-inner,
  &.mode-dark.mode-nav header.header .r .hamburger-inner::after,
  &.mode-dark.mode-nav header.header .r .hamburger-inner::before {
    background-color: $dark;
  }
  &.mode-dark.mode-nav header.header .r .hamburger-inner {
    background-color: rgba(0,0,0,0) !important;
  }
  &.mode-dark.mode-film header.header .r .hamburger-inner,
  &.mode-dark.mode-film header.header .r .hamburger-inner::after,
  &.mode-dark.mode-film header.header .r .hamburger-inner::before {
    background-color: $dark;
  }
  &.mode-dark.mode-film header.header .r .hamburger-inner {
    background-color: rgba(0,0,0,0) !important;
  }
  &.mode-dark.mode-buy header.header .r .hamburger-inner,
  &.mode-dark.mode-buy header.header .r .hamburger-inner::after,
  &.mode-dark.mode-buy header.header .r .hamburger-inner::before {
    background-color: $dark;
  }
  &.mode-dark.mode-buy header.header .r .hamburger-inner {
    background-color: rgba(0,0,0,0) !important;
  }
  &.mode-dark.mode-profile header.header .r .hamburger-inner,
  &.mode-dark.mode-profile header.header .r .hamburger-inner::after,
  &.mode-dark.mode-profile header.header .r .hamburger-inner::before {
    background-color: $dark;
  }
  &.mode-dark.mode-profile header.header .r .hamburger-inner {
    background-color: rgba(0,0,0,0) !important;
  }


  #mobile-nav-overlay-outer.mode-buy .bugs-outer .bug .bug-label {
    color: $dark;
  }


  &.page-film .showtimes-outer .date-boxes .box {
    border-color: $light;
  }
  &.page-film .showtimes-outer .date-boxes .box .box2 .month,
  &.page-film .showtimes-outer .date-boxes .box .box2 .day,
  &.page-film .showtimes-outer .date-boxes .box .box2 .weekday {
    color: $light;
  }
  &.page-film .showtimes-outer .date-boxes .box.active,
  &.page-film .showtimes-outer .date-boxes .box:hover {
    background-color: $light;
    .box2 {
      .month, .day, .weekday {
        color: $dark;
      }
    }
  }
  &.page-film .showtimes-outer .theater-rows .theater-rows-date .theater-row .theater-row-lr .times .time {
    background-color: $light;
    color: $dark;
    border-color: $light;
    &:hover {
      background-color: $dark;
      color: $light;
    }
  }
  &.page-film .showtimes-outer .showtimes-search input {
    background-color: transparent;
    border-color: $light;
    color: $light;
  }
  .quick-thumbs-side {
    background-color: rgba(0,0,0,0);
    &:hover {
      background-color: rgba(0,0,0,.8);
    }
  }

  #become-member-popup .inner1 .rte .btn {
    color: $dark;
    background-color: $light;
    border-color: $dark;
    &:hover {
      color: $light;
      background-color: $dark;
      border-color: $dark;
    }
  }

  &.page-film .showtimes-outer #showtimes-search-button2 svg {
      path {
        fill: $light;
      }
  }
  .content-block.specialNote {
    .black-bar {
      background-color: $light;
    }
    .specialNote2 {
      background-color: $dark;
    }
  }
}