.rte {
  &.center {
    margin-left: auto;
    margin-right: auto;
  }
  &.text-center {
    text-align: center;
    @media only screen and (max-width: $breakpoint-small) {
      text-align: left;
    }
    h1.tighter {
      margin-left: auto;
      margin-right: auto;
      max-width: 800px;
    }
  }

  &:not(.no-max-width) {
    max-width: $max-width-copy;
  }

  color: $color-dark;
  width: 100%;
  @include font-global-regular();
  font-size: 18px;
  line-height: 1.5em;


  h1, h2, h3, h4, h5, h6 {
    line-height: 1.1em;
    word-break: break-word;
    margin:0 0 10px 0;
  }

  h1 {
    font-size: 62px;
    text-transform: uppercase;
    @include font-adrianna-light();
    @media only screen and (max-width: $breakpoint-small) {
      font-size: 40px;
    }
  }

  h2 {
    font-size: 42px;
    text-transform: uppercase;
    @include font-adrianna-light();
    @media only screen and (max-width: $breakpoint-small) {
      font-size: 32px;
    }
  }

  h3 {
    font-size: 28px;
    text-transform: uppercase;
    @include font-adrianna-light();
  }

  h4 {
    font-size: 24px;
    text-transform: uppercase;
    @include font-adrianna-light();
  }

  h5, .rte-h5 {
    font-size: 18px;
    margin:0 0 15px 0;
    @include font-global-bold();
  }

  h6, .rte-h6 {
    font-size: 16px;
    @include font-global-bold();
  }


  p, a, li, a, tr, th, td {
    font-size: 16px;
    @include font-global-regular();

    b, strong {
      @include font-global-bold();
    }

    i, em {
      font-style: italic;
    }
  }

  a {
    //top:10px;
    //color:inherit!important;
    //@extend .link-underline-leftright-dark;
    @include transition-all();
    @include font-global-bold();
    color: $color-hover;
    &:hover {
      color: $color-hover;
      text-decoration: underline;
    }
    &.lightStyle {
      @include font-global-regular();
    }
  }

  p, h6 {
    word-break: break-word;
    margin-bottom: 15px;
    @media only screen and (max-width: $breakpoint-small) {
      //margin-bottom: px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  table {
    th {
      @include font-global-bold();
      font-size: 16px;
    }
    td {
      @include font-global-regular();
      font-size: 16px;
      line-height: 1.2em;
      * {
        @include font-global-regular();
        font-size: 16px;
      }
    }
  }

  b, strong, em, i, del, strike, u {
    color: inherit;
  }

  ul, ol {
    margin-bottom: 20px;
  }

  small {
    font-size: 14px;
  }

  figure {
    margin-bottom: 20px;

    img, svg {
      max-width: 100%;
    }
  }

  ul, ol {
    li {
      margin-bottom: 10px;
      margin-left: 17px;
    }
  }

  .important, .error {
    font-weight: bold;
  }

  &.tight-margins {
    p, li, h3, h4, h5, h6 {
      margin-bottom: 5px;
    }
  }


  &.light {
    color:#fff;
  }
  &.gray {
    color:$color-gray;
  }
}
