
//LEAVE AT BOTTOM - desktop, mobile global hiders
.show-desktop {
  display: inline-block;
  &.block {
    display: block;
  }
  &.flex {
    display: flex;
  }
  @media only screen and (max-width: $breakpoint-small) {
    display: none;
    &.block {
      display: none;
    }
    &.flex {
      display: none;
    }
  }
}
.show-mobile {
  display: none;
  &.block {
    display: none;
  }
  &.flex {
    display: none;
  }
  @media only screen and (max-width: $breakpoint-small) {
    display: inline-block;
    &.block {
      display: block;
    }
    &.flex {
      display: flex;
    }
  }
}


// ONEID
.hook-oneid-login, .hook-oneid-logout, .hook-oneid-postinit {
  display: none;
}
body.blr {
  .container-all, .header {
    filter: blur(3px);
  }
}
body.page-member-portal {
  #become-member-popup {
    .global-close {
      display: none;
    }
  }
}

.black-sidebar {

  .black-sidebar-bg {
    position: absolute;
    top:0;
    left:0;
    background-color:  #000;
    width: 100px;
    height: 100%;
    padding-right: 20px;
    margin-right: 20px;
  }
  .rte {
    background-color: #fff;
    h1 {
      margin: 0!important;
      padding: 0!important;
    }
  }
}