body.page-contest {
  .top-panel {
    width:70%;
    @media only screen and (max-width: $breakpoint-small) {
      width:100%;
    }
    max-width: 650px;
    margin-bottom: 40px;

    .quick-thumbs-dots-arrows {
      display: flex;
    }
  }

  .rte {
    a {
      color:#000;
      text-decoration: underline;
    }
    h2, h3, h4, h5, h6, h7 {
      @include font-adrianna-regular();
      margin-top: 30px;
    }
  }
  .description-holder, .button-holder {
    margin: 20px 0;
  }

}
