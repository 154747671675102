.content-block.textColumnImageColumn {
  .lr {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: $breakpoint-small) {
      display: block;
    }
    &.showTextColumnOnRight {
      flex-direction: row-reverse;
    }
    .rte {
      h5 {
        @include font-adrianna-light();
      }
      .btn {
        @media only screen and (max-width: $breakpoint-small) {
          margin:0 0 20px;
        }
      }
    }
    .l {
      width:50%;
      @media only screen and (max-width: $breakpoint-small) {
        width: 100%;
      }
      .desc {
        width: 100%;
        max-width: 480px;
      }


    }
    .r {
      width:45%;
      @media only screen and (max-width: $breakpoint-small) {
        width: 100%;
      }
      img {
        width: 100%;
      }
    }

  }
}