$time:150ms;
#featured-panel {
  background-color: #000;
  position: relative;
  width: 100%;
  overflow: hidden;
  opacity: 0;
  @include vh100();
  @include transition-all();
  &.loaded {
    opacity: 1;
  }
  &.device { //set by js
    min-height: unset!important;
  }
  .capper, .capper-padded {
    height: 100%;
  }
  .panels, .panel {
    position: absolute;
    top:0;
    left: 0;
    width:100%;
    height: 100%;
    overflow: hidden;
  }
  .panels {
    .panel {
      opacity:0;
      @include willChange();
      transition: opacity $time ease-in-out;
      &.active {
        opacity:1;
      }
      .video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &.active {
        //opacity:1;
      }
    }
  }
}

.film-titles {
  position: absolute;
  top:0;
  left:0;
  height: 100%;
  width: 100%;
  .capper, .capper-padded {
    height: 100%;
  }
  .film-titles2 {
    position: relative;
    top:0;
    left:0;
    height: 100%;
  }
  .dots, ul {
    bottom: 100px;
    @media only screen and (max-width: $breakpoint-small) {
      bottom: 50px;
    }
  }
  .dots {
    position: absolute;
    right:0;
    width:12px;
    margin-bottom:12px;
    @media only screen and (max-width: $breakpoint-small) {
      margin-bottom:8px;
    }
    .dot {
      cursor: pointer;
      margin:20px auto 0;
      width:6px;
      height: 6px;
      border-radius: 50%;
      background-color: #fff;
      display: block;
      opacity: 0.44;
      transition: opacity $time ease-in-out;
      @include willChange();
      &.active {
        width:12px;
        height: 12px;
        opacity: 1;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
  ul {
    position: absolute;
    left:0;
    width: calc(100% - 30px);
    li {
      list-style: none;
      a {
        .inner {
          margin-top: 10px;
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;
          @media only screen and (max-width: $breakpoint-small) {
            display: block;
          }
          .txt {
            max-width: 1050px;
            @include font-adrianna-light();
            //font-size: 50px;
            font-size: clamp(35px, 3vw, 50px);
            color:#fff;
            text-transform: uppercase;
            //@include transition-all();
            @include willChange();
            transition: font-size $time ease-in-out;
            @media only screen and (max-width: $breakpoint-small) {
              font-size: 40px;
            }
          }
          .decorations {
            margin-left: 40px;
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            @media only screen and (max-width: $breakpoint-small) {
              margin-left: 0;
              margin-top: 10px;
            }
            span {
              margin-left: 10px;
              &:first-child {
                margin-left: 0;
              }
            }
            .decorations-txt {
              @include font-adrianna-condensed-regular();
              font-size: 30px;
              color:#fff;
            }
            .decorations-icon {
              svg {
                width: 54px;
              }
              &.rt {
                img {
                  width: 50px;
                  height: auto;
                  margin-left: 10px;
                }
              }
              &.nyt {
                img {
                  width: 46px;
                  height: auto;
                  margin-left: 10px;
                  position: relative;
                  top:-2px;
                }
              }
            }
          }
        }
        &.active, &:hover {
          .inner {
            .txt {
              //font-size: 72px;
              font-size: clamp(55px, 3.5vw, 72px);
              @include font-adrianna-regular();
              @media only screen and (max-width: $breakpoint-small) {
                font-size: 40px;
                @include font-adrianna-light();
              }
            }
            .decorations {

            }
          }
        }
        @media only screen and (max-width: $breakpoint-small) {
          &:not(.active) {
            display: none;
          }
        }
      }
    }
  }
}



body.page-home {
  // SPEICAL MOBILE
  @media only screen and (max-width: $breakpoint-small) {
    &.fixed {
      position: fixed;
      top:0;
      left:0;
      overflow: hidden;
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
      width: 100vw;
    }
  }
  &.fixed-video {
    position: fixed;
    top:0;
    left:0;
    overflow: hidden;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
  }
}