$breakpoint-quickthumbs: 550px;
.quick-thumbs, .quick-thumbs-noswiper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  &.noswiper {
    display: block;
  }
  //opacity: 0;
  &.init {
    //opacity: 1;
  }
  @media only screen and (max-width: $breakpoint-small) {
    //@include calc(width,'100%');
    //margin-left: auto;
    //margin-right: auto;
  }
  @media only screen and (max-width: $breakpoint-quickthumbs) {
    width: 100%;
  }

  // hide first peek of swiper
  .swiper-wrapper .swiper-slide.first-visible {
    //visibility: hidden;
  }
  &.clicked-once {
    .swiper-wrapper .swiper-slide.first-visible {
     // visibility: visible;
    }
  }

  .swiper-wrapper, .swiper-slide {
    width:100%;
  }

  .quick-thumbs-outer {
    width: 100%;
    //display: flex;
    height: auto;
    .swiper-slide {
      display: flex;
      align-items: center;
      .bgimg {
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height: 100%;
      }
    }
  }
  .quick-thumbs-swiper, .quick-thumbs-noswiper {
    width:100%;
    height:100%;
    .swiper-wrapper {
      height: 100%;
      max-height: 100vw;
      /*
      // CSS Grid/Flexbox bug size workaround
      // @see https://github.com/kenwheeler/slick/issues/982
      min-height: 0;
      min-width: 0;
      max-width: 100vw;
      width: 100%;
      //overflow: hidden;

       */
    }

    .swiper-slide {
      //width: auto;
      //width: 100%;
      //flex-shrink: 0;
      display: block;
      //height: 100%;
      //max-height: 100%;
      //background-color: $color-dark;
      width: fit-content;
      .bgimg {
        opacity:1;
        @include transition-all();
      }
      .bgimg-outer, .bgimg-outer a {
        display: block;
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
      }
      .bottom-bar {
        position: absolute;
        bottom:0;
        left:0;
        width: 100%;
        .bottom-bar2 {
          padding:10px;
          .bottom-bar3 {
            display: flex;
            width: 100%;
            align-items: flex-end;
            justify-content: space-between;
            .l {
              width:calc(100% - 50px);
              a {
                color:#fff;
                text-transform: uppercase;
                //@include font-adrianna-condensed-light();
                //@include font-adrianna-condensed-demibold();
                @include font-adrianna-condensed-regular();
                font-size: 24px;
                @include link-underline-leftright();
                top:9px;
                @media only screen and (max-width: $breakpoint-small) {
                  //@include font-adrianna-condensed-demibold();
                }
              }

            }
            .r {
              width:auto;
              //margin-right: 15px;
            }
          }
        }
      }

    }
    .swiper-slide-active {
      .bgimg {
        opacity:1;
      }
    }
  }

  .quick-thumbs-noswiper {
    height:auto;
    .swiper-wrapper {
      height: auto;
      max-height: unset;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 15px;
      row-gap: 15px;
      @media only screen and (max-width: 1100px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media only screen and (max-width: 900px) {
        grid-template-columns: repeat(2, 1fr);
      }
      .swiper-slide {
        aspect-ratio: 1;
        width: 100%;
        display: none;
      }
    }

  }

  &.video-strip {
    .quick-thumbs-outer .swiper-slide .bgimg {
      aspect-ratio: unset;
      width:100%;
      height: 100%;
    }

  }


  &.simple-square {
    .quick-thumbs-outer {
      aspect-ratio: 1;
      .swiper-slide {
        cursor: pointer;
      }
      .icon {
        width: 40px;
        height: 40px;
        cursor: pointer;
        position: absolute;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
        display: none;
      }
    }

  }


  &.flexible-format {
    opacity: 0;
    &.init {
      opacity: 1;
    }
    .quick-thumbs-outer {
      //aspect-ratio: 1;
      .swiper-slide {
        cursor: pointer;

        .bgimg-outer {
          position: relative;
          width: 100%;
          height: auto;
          @media only screen and (max-width: $breakpoint-small) {
            width: 100%;
          }
        }
        img {
          width: 100%;
          height: auto;
        }
      }
      .icon {
        display: none;
      }
    }

  }


} //quick-thumbs

.quick-thumbs-side {
  position: absolute;
  @include widthMaxMin(60px);
  //@include widthMaxMin($master-padding-2x);
  @media only screen and (max-width: $breakpoint-small) {
    //@include widthMaxMin($master-padding-mobile-2x);
  }
  top:0;
  width: 100%;
  display: flex;
  background-color: rgba(29, 29, 29, 0.61);
  height: 100%;
  z-index: 2;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  @include transition-all();
  &.prev {
   left:0;
  }
  &.next {
    right:0;
  }
  .btn-prev {
    span {
      transform: rotate(180deg);
    }
  }
  .btn-next {
    span {

    }

  }
  .btn-prev, .btn-next {
    span {
      display: block;
    }
  }
  &:hover {
    background-color: rgba(29, 29, 29, 0.8);
  }

  @media only screen and (max-width: $breakpoint-small) {
    display: none!important;
  }
}

.strip-hider-trigger {
  &:not(.no-pad) {
    @include master-padding-lr();
  }
  .strip-hider-trigger2 {
    width: 100%;
    .quick-thumbs-swiper {
      overflow: visible;
    }
    &.clicked-once {
      .quick-thumbs-swiper {
        overflow: visible;
      }

    }
  }
  &.no-pad {
    width: 100%;
  }
}

.quick-thumbs-dots-arrows {
  display: none;
  margin-top: 20px;
  text-align: center;
  justify-content: center;
  align-items: center;
  .swiper-button-prev, .swiper-button-next {
    left:0;
    position: relative;
    display: inline-block;
    width:24px;
    height:24px;
    margin-top: 0;
    top:0;
    bottom: unset;
    svg {
      width:100%;
      height:100%;
    }

    &.swiper-button-disabled {
      opacity: 10;
    }
  }


  .quick-thumbs-dots {
    display: block;
    margin:0 15px;
    .dots {
      top:-2px;
      bottom: unset;
      text-align: center;
      .swiper-pagination-bullet {
        background-color: #000;
      }
    }
  }
}


.quick-thumbs[data-type="full-bleed-carousel-strip"] {


  .swiper-slide {
    width: 50vw;
    flex-shrink: 0;
  }

  .quick-thumbs-swiper {
    overflow: hidden;
  }

  .swiper-wrapper {
    justify-content: center;
  }



}
