/********************************************************
PRECLEAR -
********************************************************/
* {
    position: relative;
	margin:0;
	padding:0;
	outline: none!important;  //disables ugly outline
	-webkit-text-size-adjust: 100%;  //stops ios from resizing copy automatically
	-webkit-font-smoothing:antialiased; //font weight fix
	text-shadow: 1px 1px 1px rgba(0,0,0,0.004); //font weight fix
	-webkit-tap-highlight-color: rgba(0,0,0,0)!important; //disables ugly highlight
} 
div { 
	-webkit-overflow-scrolling: touch; /* momentum scrolling for any overflow:scroll */
}
textarea {
	resize:none;
}  
img { 
	border:0;  //ensures no border
	vertical-align:top;  //vertically aligns image, ie fix
	//disables ugly select
    -ms-user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	user-select: none;
} 
form, 
fieldset, 
table  { 
	border:0;
}
table {
	border-collapse: collapse;  //table cells share border
	border-spacing: 0;
}
input { 
	box-shadow: none;
	border:0;
	border-radius: 0;
}
sup { 
	font-size:50%;
	font-family:inherit;
}
.ui-loader { 
	display:none; //hide mobile jquery feature
}
a {
  -webkit-transform: translateZ(0)!important; //safari blue ugly
  outline-style: none;
  border-color:transparent;
  &:active,
  &:visited {
    outline:none!important;
    border: none;
    box-shadow:none;
    border-color:transparent;
  }
}