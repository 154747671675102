.content-block.filmEntryStrip {
  &.sublabel {
    margin:20px 0 60px 0;
  }
  .quick-thumbs-side {

  }
  .film-strip-outer {
    height: auto;
    // WIDTH set via JS
  }
  .film-strip {
    width: 100%;
    //aspect-ratio: 4;
    .quick-thumbs {
      //width: 100%;
      height: 100%;
      width: 100%;
      @media only screen and (max-width: $breakpoint-small) {
        width: calc(100% - $mobile-thumbs-peek);
      }
      .swiper-slide {
        aspect-ratio: 1;
        @include punch-in-hover();
      }
    }
  }
  .desc {
    margin-bottom: 20px;
  }
  .rte {
    h1, h2, h3, h4, h5, h6 {
      text-transform: uppercase;
    }
  }


}