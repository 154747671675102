#become-member-popup {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999999;
  top:0;
  left:0;
  background-color: rgba(30,30,30,0.65);
  #become-member-popup2 {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    .icon-rows {
      .icon-row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        .members-icon {
          @include widthMaxMin(48px);
          @include heightMaxMin(48px);
          border: 1px solid #000;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            width: 20px;
            height: auto;
          }
        }
        .members-txt {
          width: 100%;
          margin-left: 20px;
        }
      }
    }
    .center {
      text-align: center;
      .hook-oneid-login-clicker {
        //@extend .link-underline-leftright-dark;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .inner1 {
    width: 95%;
    max-width: 600px;
    background-color: #fff;
    border-radius: 20px;
    .inner2 {
      padding:30px;
      @media only screen and (max-width: $breakpoint-small) {
        padding:20px;
      }
    }
    .logo {
      width: 130px;
      margin-bottom: 20px;
      svg {
        width: 100%;
        height: auto;
      }
    }
    .global-close {
      position: absolute;
      top:30px;
      right:30px;
      @media only screen and (max-width: $breakpoint-small) {
        top:10px;
        right:10px;
      }
    }
    .rte {
      * {
        @media only screen and (max-width: $breakpoint-small) {
          line-height: 1.2em;
        }
      }
      .btn {
        width: 100%;
        margin-bottom: 15px;
      }
      ul {
        margin-bottom: 0;
        li {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}