.all-library {
  .lr {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media only screen and (max-width: $breakpoint-quickthumbs) {
      display: block;
    }
    .l {
      min-width: 250px;

    }
    .r {
      width: 100%;
      display: inline-flex;
      align-items: flex-start;
      justify-content: flex-end;
      text-align: right;
      @media only screen and (max-width: $breakpoint-quickthumbs) {
        justify-content: flex-start;
        text-align: left;
      }
      span {
        font-size: 16px;
        @include font-global-regular();
        margin-left: 10px;
        &.txt-label {
          margin-left: 0;
        }
        &.txt-clicker {
          color:$color-dark;
          &.selected {
            color:$color-dark;
            @include font-global-bold();
          }
          &.notselected {
            cursor: pointer;
            @extend .link-underline-leftright-dark;
            color:$color-dark;
          }

        }

      }
    }
  }

  .film-strip-load-more {
    width: 100%;
    margin: 40px 0;
    text-align: center;
  }
}